<template>
  <div>
    <!-- 增加两个勾选项，“我想成为案主”和“我想成为主持人”； -->
    <div class="radio-box fs28">
      <van-checkbox-group v-model="result" class="dfc main-around h100">
        <van-checkbox name="1" checked-color="#F2C984" shape="square"
          >我想当案主</van-checkbox
        >
        <van-checkbox name="2" checked-color="#F2C984" shape="square"
          >我想成为主持人</van-checkbox
        >
      </van-checkbox-group>
    </div>
    <div v-if="isHost && publisher.user" class="field-box">
      <div class="field-name fs28 fc666">
        发布人<span class="desc">主持人可代私董发布参会感想</span>
      </div>
      <div class="field-content" @click.stop="showPopup = true">
        <span class="selected-user  fs28">{{ publisher.user.name || "" }}</span>
        <van-icon name="arrow" size=".28rem" />
      </div>
    </div>
    <div class="title fs28 fc666">参会感想</div>
    <div class="inner help">
      <van-field
        class="h100"
        v-model.trim="message"
        rows="20"
        type="textarea"
        placeholder="说说你的参会感想"
      />
    </div>

    <div class="b-button-box bgfff">
      <div class="recruiting-button dfc flex-center">
        <div @click="onSubmit" class="dfr flex-center">
          <icy-button height="0.8rem" border-radius="0.44rem"
            >发布参会感想</icy-button
          >
        </div>
      </div>
    </div>

    <van-popup v-model="showPopup" position="top" :style="{ height: '6rem' }">
      <div
        v-for="item in list"
        :key="item.id"
        class="option-wrap"
        @click.stop="onSelect(item)"
      >
        <div class="left">
          <img :src="item.user.avatar" class="avatar" />
          <div class="name">{{ item.user.name }}</div>
        </div>
        <div class="right">
          <van-icon
            v-if="publisher.user_id === item.user_id"
            name="success"
            color="#e3b06b"
            size=".36rem"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Dialog } from "vant";
export default {
  data() {
    return {
      message: "",
      id: "",
      routerType: 0,
      user_id: 0,
      showPopup: false,
      isHost: false,
      list: [],
      publisher: {
        user: {},
        user_id: -1
      },
      // 是否私董
      isSidong: "",
      result: [],
      // 勾选了传递1 ,不勾选不传
      want_become_driven: "", //想成为案主
      want_become_host: "" //想成为主持人
    };
  },
  created() {
    this.$title("说说参会感想");
    this.id = parseInt(this.$route.query.id) || "";
    this.routerType = parseInt(this.$route.query.type) || 0;
    this.isSidong = parseInt(this.$route.query.isSidong);
    this.initUserInfo();
  },
  mounted() {},
  methods: {
    onSubmit(){
      if (!this.message) {
        this.$toast("请填写你的参会感想内容");
        return;
      }
      if (!this.id) {
        this.$toast("未找到对应会议");
        this.$goReplace("home");
        return;
      }
      let data = {
        id: this.id,
        content: this.message
      };
      //  radio:1 想当案主 radio:2 想成为主持人
      if (this.result.length) {
        if (this.result.length == 1) {
          if (this.result[0] == 1) {
            data = {
              id: this.id,
              content: this.message,
              want_become_driven: 1
            };
          } else {
            data = {
              id: this.id,
              content: this.message,
              want_become_host: 1
            };
          }
        } else if (this.result.length == 2) {
          data = {
            id: this.id,
            content: this.message,
            want_become_driven: 1,
            want_become_host: 1
          };
        }
      }

      // 主持人代发布
      if (this.isHost && this.publisher.user_id !== this.user_id) {
        Dialog.confirm({
          title: "参会感想确认",
          message: `请与”${this.publisher.user.name}“确认后发布`,
          confirmButtonColor: "#e3b06b"
        }).then(() => {
          data["user_id"] = this.publisher.user_id;
          this.helpSubmit(data)
        });
      } else {
        this.helpSubmit(data)
      }
    },
    async helpSubmit(data) {

      const resp = await this.$API
        .post("addFeelingContent", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.$toast.success("添加成功");
        this.$goReplace("meetingDetail", {
          active: 4,
          id: this.id,
          type: this.routerType
        });
      }
    },
    async initList() {
      const data = {
        id: this.id
      };
      const resp = await this.$API
        .get("joinMeetingSidongList", data)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.list = [...this.list, ...resp.data.list];
      }
    },
    // 获取用户
    async initUserInfo() {
      const resp = await this.$API.get("userInfo").then(resp => resp);
      const detail = await this.$API.get('meetingDetail', {id: this.id}).then(resp => resp)
      if (resp.code == 1000 && detail.code == 1000) {
        const id = resp.data.info.id;
        this.user_id = id;
        this.publisher = {
          user: resp.data.info,
          user_id: id
        };
        this.list.push(this.publisher);

        if (id === detail.data.host_user.id) {
          this.isHost = true;
          this.initList();
        }
      }
    },
    onSelect(item) {
      this.publisher = item;
      this.showPopup = false;
    },
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.title {
  padding: 0.36rem;
  height: 1rem;
}
.field-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.36rem;
  height: 1.12rem;

  .field-name {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .desc {
      margin-left: 0.1rem;
      font-size: 0.22rem;
      color: #ccc;
    }
  }

  .field-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }
}
.option-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem 0.36rem;
  .left {
    display: flex;
    align-items: center;

    .avatar {
      margin-right: 0.3rem;
      flex-shrink: 0;
      width: 1rem;
      height: 1rem;
      object-fit: cover;
      border-radius: 50%;
    }

    .name {
      color: #333;
      font-size: 0.32rem;
    }
  }
}
.inner {
  width: 6.78rem;
  margin: 0 auto;
  min-height: calc(100vh - 1rem - 1.8rem - 2.1rem);
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .inner {
    min-height: calc(100vh - 1rem - 1.8rem - 2.1rem); //- 15px - 1.8rem
  }
}

// iphone Xs Max
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
  .inner {
    min-height: calc(100vh - 1rem - 1.8rem - 2.1rem); //- 15px - 1.8rem
  }
}

// iphone XR
@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  .inner {
    min-height: calc(100vh - 1rem - 1.8rem - 2.1rem); //- 15px - 1.8rem
  }
}
.radio-box {
  width: 7.5rem;
  height: 1.8rem;
  padding: 0.2rem 0.36rem;
  box-sizing: border-box;
  border-bottom: 0.01rem solid #eee;
}

.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-button {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
